<template>
    <el-table v-if="data.length > 0" :data="data">
        <el-table-column :label="$t('employees.employee')" prop="name"  :show-overflow-tooltip="true">
            <template slot-scope="scope">
                {{ scope.row.employee_name }} {{ scope.row.employee_surname }}
            </template>
        </el-table-column>
        <el-table-column :label="$t('employees.client')" prop="name"  :show-overflow-tooltip="true">
            <template slot-scope="scope">
                {{ scope.row.client_name }} {{ scope.row.client_surname }}
            </template>
        </el-table-column>
        <el-table-column :label="$t('employees.street')" prop="name"  :show-overflow-tooltip="true">
            <template slot-scope="scope">
                {{ scope.row.street }}, {{ scope.row.city }}
            </template>
        </el-table-column>
        <el-table-column :label="$t('distance')" prop="name"  :show-overflow-tooltip="true">
            <template slot-scope="scope">
                {{ scope.row.distance }}m
            </template>
        </el-table-column>
        <el-table-column :label="$t('gps_accuracy')" prop="name"  :show-overflow-tooltip="true">
            <template slot-scope="scope">
                {{ scope.row.accuracy }}m
            </template>
        </el-table-column>
        <el-table-column :label="$t('in_or_out')" prop="name"  :show-overflow-tooltip="true">
            <template slot-scope="scope">
                {{ scope.row.in_or_out }}
            </template>
        </el-table-column>
        <el-table-column prop="name" width="120">
            <template slot-scope="scope">
                {{ scope.row.created_at_format }}
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        data: {
            type: Array,
            default() { return []; },
        },
    },

    methods: {
        handleArchive(uuid) {
            this.$emit('handleArchive', uuid);
        },

        nameFormatter(row) {
            return `${row.name} ${row.surname}`;
        },
    },
};
</script>
